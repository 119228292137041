import React, {memo, Suspense, useCallback, useEffect, useState} from 'react'
import {Button, Space} from 'antd'
import './index.css';
import {ProductsDetail, AddProductModal} from "../../../../../../components/v2/product-detail";
import {useDispatch} from "react-redux";
import {getOrderDetailData} from "../../../../../../store/ordersDetail/action-creators";
import {clearOrdersDetail} from "../../../../../../store/ordersDetail/actions";
import PrintButton from "./print-button";


const ProductsTab = ({id}) => {
    const dispatch = useDispatch();
    const entityType = 'case';

    const fetchData = useCallback(() => [
        dispatch(getOrderDetailData(entityType, id))
    ], [entityType, id])

    useEffect(() => {
        fetchData()
        return () => {
            dispatch(clearOrdersDetail());
        };
    }, [])

    const [isOpenModalAddProduct, setIsOpenModalAddProduct] = useState<boolean>(false)

    const handleCancel = useCallback(() => {
        setIsOpenModalAddProduct(false)
    }, [])

    const handleAddProductClick = useCallback(() => {
        setIsOpenModalAddProduct(true)
    }, [])


    return (
        <Suspense fallback={''}>
            <Space style={{marginBottom: 20}}>
                <Button onClick={handleAddProductClick}>Добавить изделие</Button>
                {/*<PrintButton id={id}/>*/}
                {/*<Button onClick={onPrint}>Экспорт в эксель</Button>*/}
            </Space>
            <ProductsDetail id={id} entityType={entityType} reloadData={fetchData}/>
            <Suspense fallback={''}>
                <AddProductModal
                    entityId={id}
                    entityType={entityType}
                    reloadData={fetchData}
                    onCancel={handleCancel}
                    isOpen={isOpenModalAddProduct}
                />
            </Suspense>
        </Suspense>
    )
}

export default memo(ProductsTab);
