import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Alert, Form, Modal, Skeleton } from 'antd'
import { useSelector } from 'react-redux'
import {
  getOrdersFormData,
  getOrdersFormError,
  getOrdersFormLoading,
  getVisibleFormData,
} from '../../selectors'
import { useDebounce } from 'src/react-app/hooks/useDebounce/useDebounce'
import { HStack, VStack } from '../../../../../ui/Stack'
import { HSteps } from '../../../../../ui/Steps'
import { DefaultJsonForm } from '../../../../../components/v2/default-json-form'
import { FormsHeader } from './form-header'
import { useSaveResult } from '../../hooks/useSaveResult'
import { useHistory } from 'react-router-dom'
import { MeasurementModal } from '../modals/measurement'
import { isFormDataRequired } from '../../../../../../lib/utils/collections'

interface OrdersFormsProps {
  className?: string
  id: string | number
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
}

export const Forms: React.FC<OrdersFormsProps> = memo((props: OrdersFormsProps) => {
  const { id } = props
  const [form] = Form.useForm()
  const ordersFormData = useSelector(getOrdersFormData)
  const ordersFormIsLoading = useSelector(getOrdersFormLoading)
  const ordersFormError = useSelector(getOrdersFormError)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isCustomer, setIsCustomer] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>()
  const visibleFormData = useSelector(getVisibleFormData)
  const { saveResult } = useSaveResult()
  const history = useHistory()
  const formRef = useRef<typeof visibleFormData>()

  useEffect(() => {
    if (visibleFormData) {
      formRef.current = visibleFormData
    }
  }, [visibleFormData])

  const getModalSchema = useMemo(() => {
    if (isCustomer) {
      const required = visibleFormData?.modalFieldsSchema?.required?.filter(item => item !== 'comment' && item !== 'measurement_time')
      const props = visibleFormData?.modalFieldsSchema?.properties as Record<string, any>
      if (props && required && 'send_tg_notification' in props && 'measurement_time' in props && 'comment' in props) {
        const { send_tg_notification, measurement_time, comment, ...rest } = props
        return { required, properties: rest }
      } else {
        return visibleFormData?.modalFieldsSchema
      }
    }
    const required = visibleFormData?.modalFieldsSchema?.required?.filter(item => item !== 'measurement_scan')
    const props = visibleFormData?.modalFieldsSchema?.properties as Record<string, any>
    if (props && required && 'measurement_scan' in props) {
      const { measurement_scan, ...rest } = props
      return { required, properties: rest }
    } else {
      return visibleFormData?.modalFieldsSchema
    }
  }, [isCustomer, visibleFormData])

  const saveCase = useCallback(async (isNextStage = false) => {
    await saveResult(
      id,
      formRef.current,
      ordersFormData?.stages?.current,
      isOpenModal,
      setIsOpenModal,
      isNextStage
    )
  }, [history, id, formRef])

  useEffect(() => {
    const redirectUrl = ordersFormData?.redirectUrl
    if (redirectUrl) {
      history.push(redirectUrl)
    }
  }, [ordersFormData])

  const debounceSaveCase = useDebounce(saveCase, 1000)

  const onChangeForm = useCallback(
    async ({ formData }) => {
      console.log({ formData })
      if (!formRef.current) {
        return
      }
      formRef.current.resultObject = formData
      debounceSaveCase()
    }, [formRef, debounceSaveCase]
  )

  const handleOnClick = useCallback(async () => {
    setIsDisabled(true)
    await saveCase(true)
    setIsDisabled(false)
  }, [saveCase])

  if (ordersFormError && !ordersFormIsLoading && !ordersFormData) {
    return (
      <HStack gap={'8'} className={'mapWrapper'}>
        При выполнении запроса возникла ошибка.
        Попробуйте перезагрузить страницу или открыть карточку повторно.
      </HStack>
    )
  }

  const onFormDataModal = useCallback(
    async ({ formData }) => {
      setIsCustomer(
        formData?.measurement_responsible?.length == 1
        && [11, 19, 20].includes(formData?.measurement_responsible?.[0])
      )
      if (!formRef.current) {
        return
      }
      const isFilesEdit = JSON.stringify(formData) === JSON.stringify(formRef.current?.modal)
      if (isFilesEdit) {
        return
      }
      formRef.current.modal = { ...formData }
    }, [formRef]
  )

  const handleCancel = useCallback(() => {
    setIsOpenModal(false)
  }, [setIsOpenModal])

  const handleOk = useCallback(async () => {
    if (!formRef.current) {
      return
    }
    const schema = getModalSchema
    const isRequiredModal = isFormDataRequired(formRef.current.modal, schema)
    if (!isRequiredModal) {
      Modal.warning({
        title: 'Предупреждение',
        content: 'Заполните все обязательные поля'
      })
      return
    }
    // @ts-ignore
    formRef.current.modalFieldsSchema = schema

    setIsDisabled(true)
    setIsOpenModal(false)
    await saveCase(true)
    setIsDisabled(false)
  }, [setIsDisabled, saveCase, formRef, getModalSchema])

  return (
    <>
      <FormsHeader
        id={id}
        stage={ordersFormData?.stages?.current}
        nextStageOnClick={handleOnClick}
      />
      <HStack gap={'8'} className={'mapContent'} max>
        <Form
          {...formItemLayout}
          layout='horizontal'
          form={form}
          scrollToFirstError={true}
          style={{ width: '100%' }}
        >
          {ordersFormError && !ordersFormIsLoading ? (
            <div className={'alertWrapper'}>
              <Alert
                message='При выполнении операции возникла ошибка:'
                showIcon
                type='error'
                description={ordersFormError}
              />
            </div>
          ) : null}
          {!ordersFormIsLoading ? (
            <VStack gap={'16'} max align={'stretch'} className={'ordersForm'}>
              {ordersFormData?.stages && (
                <HSteps
                  items={Object.values(ordersFormData?.stages?.items)}
                  current={ordersFormData?.stages?.current}
                  history={ordersFormData?.stages?.history}
                />)
              }
              {
                visibleFormData && <DefaultJsonForm
                  formData={visibleFormData.resultObject}
                  schema={visibleFormData.resultSchema}
                  onChange={onChangeForm}
                  orientation='horizontal'
                />
              }
            </VStack>
          ) : (
            <Skeleton active />
          )}
        </Form>
        {
          visibleFormData && (
            !ordersFormIsLoading && visibleFormData?.modal && (
              <MeasurementModal
                schema={getModalSchema}
                onChange={onFormDataModal}
                formData={visibleFormData.modal}
                isOpen={isOpenModal}
                onCancel={handleCancel}
                onOk={handleOk}
                centered={true}
                disabled={isDisabled}
                title={visibleFormData?.modalTitle}
              />
            )
          )
        }
      </HStack>
    </>
  )
})
