import React, { memo, useCallback, useEffect, useState } from 'react'
import { Alert, Form, Skeleton } from 'antd'
import { useSelector } from 'react-redux'
import {
  getDeliveriesFormData,
  getDeliveriesFormError,
  getDeliveriesFormLoading,
  getVisibleFormData,
} from '../selectors'
import { useDebounce } from 'src/react-app/hooks/useDebounce/useDebounce'
import { HStack, VStack } from '../../../../ui/Stack'
import { HSteps } from '../../../../ui/Steps'
import { DefaultJsonForm } from '../../../../components/v2/default-json-form'
import { DeliveriesFormsHeader } from './deliveries-form-header/deliveries-form-header'
import { useSaveResult } from '../hooks/useSaveResult'
import { useHistory } from 'react-router-dom'

interface DeliveriesFormsProps {
  className?: string
  id: string | number,
}

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 24},
  },
}

export const DeliveriesForms: React.FC<DeliveriesFormsProps> = memo((props: DeliveriesFormsProps) => {
  const {id} = props
  const [form] = Form.useForm()
  const deliveriesFormData = useSelector(getDeliveriesFormData)
  const deliveriesFormIsLoading = useSelector(getDeliveriesFormLoading)
  const deliveriesFormError = useSelector(getDeliveriesFormError)
  const [isDisabled, setIsDisabled] = useState<boolean>()
  const visibleFormData = useSelector(getVisibleFormData)
  const {saveResult} = useSaveResult()
  const history = useHistory()

  const saveCase = useCallback(async (nextCardType) => {
    await saveResult(id, visibleFormData, deliveriesFormData?.stages?.current, nextCardType)
  }, [history, id, visibleFormData])

  useEffect(() => {
    const redirectUrl = deliveriesFormData?.redirectUrl
    if (redirectUrl) {
      history.push(redirectUrl)
    }
  }, [deliveriesFormData])

  const debounceSaveCase = useDebounce(saveCase, 1000)

  const onChangeForm = useCallback(
    async ({formData}) => {
      if (!visibleFormData) {
        return
      }
      const isFilesEdit = JSON.stringify(formData) === JSON.stringify(visibleFormData?.resultObject)
      if (isFilesEdit) {
        return
      }
      visibleFormData.resultObject = {...formData}
      debounceSaveCase()
    }, [visibleFormData, debounceSaveCase]
  )

  const handleToFinish = useCallback(async () => {
    setIsDisabled(true)
    await saveCase('done')
    setIsDisabled(false)
  }, [isDisabled, saveCase])


  if (deliveriesFormError && !deliveriesFormIsLoading && !deliveriesFormData) {
    return (
      <HStack gap={'8'} className={'mapWrapper'}>
        При выполнении запроса возникла ошибка.
        Попробуйте перезагрузить страницу или открыть карточку повторно.
      </HStack>
    )
  }

  return (
    <>
      <DeliveriesFormsHeader
        id={id}
        stage={deliveriesFormData?.stages?.current}
        toFinish={handleToFinish}
      />
      <HStack gap={"8"} className={'mapContent'} max>
        <Form
          {...formItemLayout}
          layout='horizontal'
          form={form}
          scrollToFirstError={true}
          style={{width: '100%'}}
        >
          {deliveriesFormError && !deliveriesFormIsLoading ? (
            <div className={'alertWrapper'}>
              <Alert
                message='При выполнении операции возникла ошибка:'
                showIcon
                type='error'
                description={deliveriesFormError}
              />
            </div>
          ) : null}
          {!deliveriesFormIsLoading ? (
            <VStack gap={'16'} max align={'stretch'} className={'deliveriesForm'}>
              {deliveriesFormData?.stages && (
                <HSteps
                  items={Object.values(deliveriesFormData?.stages?.items)}
                  current={deliveriesFormData?.stages?.current}
                  history={deliveriesFormData?.stages?.history}
                />)
              }
              {
                visibleFormData && <DefaultJsonForm
                  formData={{...visibleFormData.resultObject}}
                  schema={visibleFormData.resultSchema}
                  onChange={onChangeForm}
                  orientation='horizontal'
                />
              }
            </VStack>
          ) : (
            <Skeleton active/>
          )}
        </Form>
      </HStack>
    </>
  )
})
