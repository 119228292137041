import React, { useCallback, useMemo } from 'react'
import { Checkbox } from 'antd'
import { ICheckbox } from './interface'

function InputCheckbox({ value, onChange, UISchema }: ICheckbox){ 
  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
  const label = useMemo(() => UISchema?.options?.label || 'Да', [UISchema])
  const onChecked = useCallback(({ target }) => onChange(target?.checked), [onChange])
  return <Checkbox disabled={disabled} checked={value} onChange={onChecked}>{label}</Checkbox>
}

export default InputCheckbox