import React, {memo, Suspense, useCallback, useState} from 'react'
import {HStack} from '../../../../../ui/Stack'
import { Button, Dropdown, Menu, MenuItemProps } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import {useSelector} from 'react-redux'
import {classNames} from '../../../../../lib/classNames/classNames'
import {ModalCreateTask} from 'src/react-app/views/modal-create-task-new'
import {ModalAddNote} from '../../../../../views/modal-add-notes-new'
import {Link} from 'react-router-dom'
import {createSession} from '../../selectors'
import { DropdownButtonProps } from 'antd/es/dropdown'

interface DeliveriesFormsHeaderProps {
  id: string | number,
  className?: string,
  stage?: number,
  toFinish?: () => void,
}

const titlesButton = [
  'Завершить'
]

export const DeliveriesFormsHeader: React.FC<DeliveriesFormsHeaderProps> = memo((props: DeliveriesFormsHeaderProps) => {
  const { className, id, stage = 0, toFinish } = props
  const isDisabledButtons = id === 'new'
  const { sessionData } = useSelector(createSession)

  const [isOpenModalCreateTask, setIsOpenModalCreateTask] = useState<boolean>(false)
  const [isOpenModalAddNote, setIsOpenModalAddNote] = useState<boolean>(false)

  const handleNextStageOnClick = useCallback(async () => {
    if (toFinish) {
      await toFinish?.()
    }
  }, [toFinish])

  const onCloseAddNote = useCallback(() => {
    setIsOpenModalAddNote(false)
  }, [setIsOpenModalAddNote])


  return (
    <HStack gap={'8'} className={classNames('mapWrapper', {}, [className])}>
      <HStack gap={'8'} max justify={'start'} className={'h3'}>
        {id === 'new' ? ('Новая доставка') : (<>Доставка {`№ ${id}`}</>)}
      </HStack>
      <HStack gap={'8'} max justify={'end'}>
        {
          titlesButton[stage] && (
            <Button onClick={handleNextStageOnClick}
                    style={{marginBottom: 16, marginTop: 16}}
                    type="default"
                    disabled={isDisabledButtons}
            >
              {titlesButton[stage] ?? ''}
            </Button>
          )
        }
        <Button
          onClick={() => setIsOpenModalAddNote(true)}
          disabled={isDisabledButtons}
        >
          Добавить заметку
        </Button>
        <ModalAddNote
          entityId={Number(id)}
          entityType={'delivery'}
          isOpen={isOpenModalAddNote}
          onClose={onCloseAddNote}
        />
      </HStack>
    </HStack>
  )
})
