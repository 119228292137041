import { createSelector } from 'reselect'
import { convertData2JsonForm } from '../../../../shared/lib/convertData2JsonForm'
import { objectMap } from '../../../../../lib/utils/get-table-transform-schema-and-form-data'
import { addEnumValBySchema } from '../../../../shared/lib/addEnumValBySchema'

export const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)
const selectOrders = state => state.order

const selectOrdersForm = createSelector(
  selectOrders,
  ({ form }) => form
)

const selectOrdersEnums = createSelector(
  selectOrders,
  ({ enums }) => enums
)

const selectOrdersStagePlan = createSelector(
  selectOrders,
  ({ stagePlan }) => stagePlan
)

export const getOrderId = createSelector(
  selectOrders,
  ({ id }) => id
)

export const getOrdersFormLoading = createSelector(
  selectOrders,
  ({ isLoading }) => isLoading
)

export const getOrdersFormError = createSelector(
  selectOrdersForm,
  ({ error }) => error
)

export const getOrdersFormData = createSelector(
  selectOrdersForm,
  ({ data }) => data
)

export const getOrdersStageCurrent = createSelector(
  selectOrdersForm,
  ({ data }) => data?.stages?.current
)

export const getOrdersEnumsData = createSelector(
  selectOrdersEnums,
  ({ data }) => data
)

export const getOrdersEnumsError = createSelector(
  selectOrdersEnums,
  ({ error }) => error
)

export const getOrdersEnumsIsLoading = createSelector(
  selectOrdersEnums,
  ({ isLoading }) => isLoading
)

const selectChatAvito = state => state.chatAvito

export const selectChatAvitoIsLoading = createSelector(
  selectChatAvito,
  ({ isLoading }) => isLoading
)

export const selectChatAvitoData = createSelector(
  selectChatAvito,
  ({ data }) => data
)

export const selectChatAvitoError = createSelector(
  selectChatAvito,
  ({ error }) => error
)

export const getActiveAvitoChatId = createSelector(
  selectChatAvito,
  ({ activeChatId }) => activeChatId
)

export const getActiveAvitoChat = createSelector(
  [selectChatAvitoData, getActiveAvitoChatId],
  (data, activeChatId) => {
    return data?.filter(chatItem => chatItem.chat_id === activeChatId)[0]
  }
)

export const getVisibleFormData = createSelector(
  [getOrdersFormData, getOrdersEnumsData],
  (formData, enums) => {
    if (formData && enums) {
      const {
        data: form,
        modal: modalData
      } = formData

      if (!form) {
        return
      }

      const {
        resultObject,
        resultSchema
      } = convertData2JsonForm(form)

      const {
        resultObject: modal,
        resultSchema: modalFieldsSchema
      } = convertData2JsonForm(modalData?.fields)

      const enumItems = enums?.['enum'] || []
      if (enums?.['city']) {
        enumItems.city_id = Object.values(objectMap(enums['city'], ([key, value]) => {
          return [key, { id: parseInt(key), value: value }]
        }))
      }

      if (enums?.['region']) {
        enumItems.region_id = Object.values(objectMap(enums['region'], ([key, value]) => {
          return [key, { id: parseInt(key), value: value }]
        }))
      }

      Object.keys(enumItems).forEach(code => {
        if (
          resultSchema?.properties.hasOwnProperty(code)
          || modalFieldsSchema?.properties.hasOwnProperty(code)
        ) {
          const enumValues = enums?.['enum']?.[code] || {}
          const oneOf: Record<string, string>[] = []

          Object.keys(enumValues).forEach(enumValue => {
            return oneOf.push({
              const: enumValues[enumValue].id,
              title: enumValues[enumValue].value,
            })
          })

          if (resultSchema?.properties.hasOwnProperty(code)) {
            addEnumValBySchema(resultSchema, code, oneOf, enumValues)
          }

          if (modalFieldsSchema?.properties.hasOwnProperty(code)) {
            addEnumValBySchema(modalFieldsSchema, code, oneOf, enumValues)
          }

          if (resultObject.hasOwnProperty(code)) {
            resultObject[code] = resultObject[code] ? parseInt(resultObject[code]) : null
          }
        }
      })

      return {
        resultSchema,
        resultObject,
        modal: modalData ? modal : null,
        modalFieldsSchema: modalData ? modalFieldsSchema : null,
        modalTitle: modalData?.title,
      }
    }
  }
)