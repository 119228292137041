import React from 'react'
import { Select } from 'antd'
import 'antd/dist/antd.css'
import IconComponent from '../icon-component'

const IconSelect = ({ iconList, value, onChange }: {iconList: string[], value?: string, onChange: (value: string) => void }) => {
  const { Option } = Select

  return (
    <Select onChange={onChange} value={value || null} defaultValue={iconList[0]} style={{ width: 120 }}>
      <Option value={null}>Отсутствует</Option>
      {iconList.map((iconName) => (
        <Option key={iconName} value={iconName}>
          <IconComponent iconName={iconName} />
        </Option>
      ))}
    </Select>
  )
}

export default IconSelect
