import { css } from 'styled-components'
import theme from './theme'

export default css`
  :root {
    font-size: ${theme.fonts.size};
  }
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
  html,
  body,
  #app {
    height: 100%;
  }
  #app {
    transition: filter 0.3s;

    &.modal-open {
      filter: blur(3px);
    }
  }
  body {
    color: ${theme.colors.black};
    font-family: ${theme.fonts.main};
    font-weight: 400;
    line-height: 20px;

    background-color: ${theme.colors.grayForBody};

    padding: 0;
  }
  * {
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  img {
    max-width: 100%;
    vertical-align: middle;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
  }

  .site-layout .site-layout-background {
    background: #fff;
  }
  [data-theme='dark'] .site-layout .site-layout-background {
    background: #141414;
  }


  .userProfile {
    background: none;
    padding: 15px;
    user-select: none;
    cursor: pointer;

    .ant-layout-sider-collapsed & {
      display: none;
    }
  }

  .mapWrapper {
    padding: 10px 0px;
  }

  .h3 {
    font-size: 18px;
    font-weight: bold;
    span{
      color: #c5bfbf;
      margin-left: 5px;
    }
  }

  .h4 {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 12px;
    span{
      color: #c5bfbf;
      margin-left: 5px;
    }
  }

  .mapContent {
    border: 1px solid #cbcbcb;
    border-radius: 5px;
    background: white;
    padding-right: 36px;
    padding-bottom: 36px;
    padding-left: 36px;
    padding-top: 36px;
  }

  .tabContent {

  }

  .alertWrapper {
    padding: 10px
  }

  .footerWrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 16px;
  }

  .taskWrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
  }

  .uploadWrapper {
    display: flex;
    width: 100%;
    max-width: 600px;
  }
  
  .ant-form-item-has-error .custom-upload-wrapper {
    border: 1px solid #ff4d4f;
    border-radius: 4px;
  }

`
