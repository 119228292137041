import React, { useState, useEffect, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import B2BLayout from '../../../layouts/b2b'
import fetchAPI from '../../../../lib/utils/fetch-api'
import { DefaultJsonForm } from '../../../components/v2/default-json-form'
import { Alert, Skeleton, Button, Tabs } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import { MapContent, MapTitle, MapWrapper, AlertWrapper, TaskWrapper } from './styles'
import { defaultSelectedSchema } from './json-schema'
import { objectToUrl, renameKeys, setEnumFields } from '../../utils'
import TabReport from '../../../pages/card-organization/tab-report'
import RatingDiler from '../../../connectors/rating-diler'
import TabRequisites from './tabs/requisite'
import TabUsers from './tabs/users'
import TabMail from './tabs/mail'
import { IBreadcrumbs } from './types'

export default function AdminNewObjectPage() {
  const { id, organization_id } = useParams() as any
  const history = useHistory()
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const [pageSuccess, setPageSuccess] = useState<string[]>([])
  const [formData, setFormData] = useState<any>({})
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<any>(null)
  const [selectedUsers, setSelectedUsers] = useState<any[]>([])
  const [organization, setOrganization] = useState<any>({})
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumbs[]>([
    { href: '/admin', title: 'Админка' },
    { href: '/new/organizations', title: 'Список организаций' },
  ])

  const saveCase = useCallback(
    async () => {
      const data = { ...formData }
      await fetchAPI(`/api/office/${id ? id : 'new'}`, {
        method: 'POST', body: JSON.stringify(data)
      }).then(result => {
        if (result?.error) {
          const errors = Array.isArray(result['errors']) && result['errors']?.length > 0
            ? result['errors'].map((error) => error['detail'])
            : ['Неизвестная ошибка при выполнении запроса']
          setPageSuccess([])
          setPageErrors(errors)
        } else {
          history.push(!id ? `/office/${result.id}` : `/offices/${organization.id}`)
        }
      })
    },
    [history, organization, id, formData],
  )

  const getData = useCallback(async () => {
    if (id) {
      const { data, organization: org } = await fetchAPI(`/api/office/${id}`)
      const fData = data.reduce((obj, cur) => ({ ...obj, [cur.name]: cur.value }), {})
      setOrganization(org)
      setFormData({
        ...fData,
        organization_id: parseInt(org.id),
      })

      const enumsList = { types: ['organizations', 'case_region'] }
      const enums = await fetchAPI(`/api/enum-list?${objectToUrl(enumsList)}`)
      const orgName = enums.organizations.find(v => +v.id === +org.id)?.name
      const orgId = parseInt(org.id)

      setBreadcrumbs(prev => ([
        ...prev,
        { href: `/offices/${orgId}`, title: `Список офисов ${orgName}` }
      ]))

      const fixKeys = renameKeys(enums, {
        organizations: 'organization_id',
        case_region: 'region_id',
      })
      setEnumFields(fixKeys, defaultSelectedSchema)
      setMatchedJSONSchema(defaultSelectedSchema)
    } else if (organization_id) {
      setFormData({ organization_id: Number(organization_id) })
      const enumsList = { types: ['organizations', 'case_region'] }
      const enums = await fetchAPI(`/api/enum-list?${objectToUrl(enumsList)}`)
      const orgName = enums.organizations.find(v => +v.id === +organization_id)?.name

      setBreadcrumbs(prev => ([
        ...prev,
        { href: `/offices/${organization_id}`, title: `Список офисов ${orgName}` }
      ]))

      const fixKeys = renameKeys(enums, {
        organizations: 'organization_id',
        case_region: 'region_id',
      })
      setEnumFields(fixKeys, defaultSelectedSchema)
      setMatchedJSONSchema(defaultSelectedSchema)
    }
    setPageErrors([])
    setIsDataLoading(false)
  }, [id, organization_id])

  useEffect(() => {
    getData().catch(e => {
      console.error({ e })
    })
  }, [getData])

  const onFormData = useCallback(({ formData }) => {
    setFormData({
      ...formData,
      district: formData?.address?.federal_district || formData?.district,
      address: formData?.address?.address || formData?.address,
      region: formData?.address?.region || formData?.region,
      city: formData?.address?.city || formData?.city,
    })
  }, [setFormData])

  const onTableSelected = useCallback(selected => setSelectedUsers(selected), [])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <MapWrapper>
        <TaskWrapper>
          <MapTitle>
            {id === 'new' ? <>Добавление офиса</> : <>Офис <span>{formData.name}</span></>}
          </MapTitle>
        </TaskWrapper>
        <MapContent>
          {pageErrors.length > 0 && (
            <AlertWrapper>
              <Alert
                message='При выполнении операции возникла ошибка:'
                showIcon
                type='error'
                description={pageErrors.join('. ')}
              />
            </AlertWrapper>
          )}
          {pageSuccess.length > 0 && (
            <div style={{ marginBottom: 40 }}>
              <Alert
                message={pageSuccess.join('. ')}
                showIcon
                type='success'
              />
            </div>
          )}
          {!isDataLoading ? (
            <>
              <DefaultJsonForm
                formData={formData}
                schema={matchedJSONSchema}
                onChange={onFormData}
                orientation='horizontal'
              />
              {id && (
                <Tabs type='card'>
                  <Tabs.TabPane tab='Пользователи' key='1'>
                    <TabUsers id={id} org_id={organization?.id} />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab='Расходы и доходы' key='2'>
                    <TabReport />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab='Рейтинг' key='3'>
                    <RatingDiler id={id} />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab='Банковские реквизиты' key='4'>
                    <TabRequisites id={id} />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab='Почта' key='5'>
                    <TabMail onChange={onTableSelected} id={id} />
                  </Tabs.TabPane>
                </Tabs>
              )}
            </>
          ) : (
            <Skeleton active />
          )}
          <Button onClick={saveCase} style={{ marginBottom: 16, marginTop: 16 }} type='primary'>
            <SaveOutlined />
            Сохранить
          </Button>
        </MapContent>
      </MapWrapper>
    </B2BLayout>
  )
}
