import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react'
import { Table, Button } from 'antd'
import { useHistory } from 'react-router-dom'
import modalAddUser from '../modals'
import fetchAPI from 'src/lib/utils/fetch-api'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import { DeleteButton } from '../../../appeals/list/columns'

const TableSelectedContext = React.createContext({})

function ButtonEdit({ id }) {
  const props = useContext<any>(TableSelectedContext)
  return <Button onClick={() => props.editRow(id)}>Редактировать</Button>
}

function TabUsers({ id, org_id }: { id: number, org_id: number }){

  const deleteRecord = useCallback(async (id) => {
    console.log('deleteRecord', id)
    try {
      await fetchAPI(`/api/delete_user?id=${id}`)
      setUsers((prevUsers) => prevUsers.filter(user => user.id !== id))
    } catch (error) {
      console.error('Ошибка при удалении пользователя:', error)
    }
  }, [])

  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(false)
  const columns = useMemo(() => [{
      title: 'Логин (e-mail или телефон)',
      width: 290,
      key: 'login',
      dataIndex: 'login'
    },
    {
      title: 'ФИО',
      width: 290,
      key: 'login',
      dataIndex: 'login',
      render: (_, item) => item?.name
    },
    {
      title: null,
      width: 290,
      key: 'edit',
      dataIndex: 'id',
      render: id => <ButtonEdit id={id}/> ,
    },
    {
      title: 'Действия',
      key: 'operation',
      // fixed: 'right',
      width: 100,
      // align: 'center',
      render: (val, { id }) => (
        <span onClick={e => {
          e.stopPropagation()
        }}>
          <DeleteButton id={id} deleteRecord={() => deleteRecord(id)} />
        </span>
      )
    }
  ], [])

  const [dataSource, setUsers] = useState<any[]>([])
  const [selected, setSelectedUsers] = useState<any[]>([])

  const getData = useCallback(async () => {
    const result = await fetchAPI(`/api/users_new?organization_id=${org_id}&office_id=${id}`)
    const data = getDataOfType(result, 'data', Array, [])
    setSelectedUsers(data.filter(i => i.is_active).map(i => i.id))
    setUsers(data)
  }, [id])

  useEffect(() => {
    getData()
  }, [getData])

  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>(selected)

  const onSelect = useCallback(async (row, state) => {
    const id = row.id
    setLoading(true)
    await fetchAPI(`/api/users-new/set`, {
      method: 'POST',
      body: JSON.stringify({ id, active: state })
    })
      .then(data => {
        setSelectedRowKeys(keys => keys.includes(data.id) ? keys.filter(key => key !== data.id) : keys.concat(data.id))
      })
      .catch(e => {
        console.error(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [id, selectedRowKeys])

  useEffect(() => {
    setSelectedRowKeys(selected)
  },[selected])

  const rowSelection = useMemo(() => ({
    selectedRowKeys,
    onSelect,
    hideSelectAll: true
  }), [selectedRowKeys, onSelect])

  const onHeaderRow = useCallback((column: any) => ({
    style: {
      fontSize: (column.key && column.key.substr(-3) === '-at') ? 9 : 13,
    }
  }), [])

  const contextProps = useMemo(() => ({
    editRow: (userId: number) => {
      history.push(`/employees_new/${userId}`)
    }
  }), [history, id])

  const addUser = useCallback(async () => {
    await modalAddUser(id, org_id, (dataSource?.length || 0)+1)
    setTimeout(() => {
      getData()
    }, 2000)
  }, [id, org_id, getData, dataSource])

  return (
    <>
      <Button onClick={addUser}>Добавить сотрудника</Button><br/><br/>
      <TableSelectedContext.Provider value={contextProps}>
        <Table
          size="small"
          rowKey="id"
          pagination={false}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          onHeaderRow={onHeaderRow}
        />
      </TableSelectedContext.Provider>
    </>
  )
}

export default TabUsers
