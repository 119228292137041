import { useCallback } from 'react'
import { isFormDataRequired } from '../../../../../lib/utils/collections'
import { Modal } from 'antd'
import { nextStage, updateFormData } from '../../../../store/deliveries/action-creators'
import { useDispatch } from 'react-redux'

export function useSaveResult() {
  const dispatch = useDispatch()
  const saveResult = useCallback(async (id, visibleFormData, stage, nextCardType) => {
    if (!visibleFormData) {
      return
    }
    const formData = {
      delivery: {...visibleFormData.resultObject},
      nextCardType,
    }

    if ((id === 'new') && !nextCardType) {
      const isRequiredFields = isFormDataRequired(formData.delivery, visibleFormData.resultSchema)
      if (!isRequiredFields) {
        return
      }
    }

    if (nextCardType) {
      const isRequiredFields = isFormDataRequired(formData.delivery, visibleFormData.resultSchema)
      if (!isRequiredFields) {
        Modal.warning({
          title: 'Предупреждение',
          content: 'Заполните все обязательные поля'
        })
        return
      }
      dispatch(nextStage(id, formData))
    } else {
      dispatch(updateFormData(id, formData))
    }
  }, []
  )
  return { saveResult }
}
