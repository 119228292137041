import fetchAPI from "../../../../lib/utils/fetch-api";

interface ICandidateIds {
    id: number;
}
export const getCandidateIds = async (formData: ICandidateIds) => {
    try {
        const response = await fetchAPI(
            `/api/v2/tasks/search/hr`,
            {
                method: 'POST',
                body: JSON.stringify(formData)
            }
        )

        if (response['errors']) {
            let error = response['errors'];
            if (Array.isArray(error)) {
                error = error.join();
            }
            throw new Error(error);
        }

        return response?.data;
    } catch (err) {
        console.error(err);
        return false;
    }
}
