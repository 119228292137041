import React, { ReactNode, useCallback, useMemo } from 'react'
import { Steps } from 'antd'
import { classNames } from '../../../lib/classNames/classNames'
import cls from '../Steps/Steps.module.css'
import './index.css'

type Status = 'wait' | 'process' | 'finish' | 'error'
type Direction = 'horizontal' | 'vertical'

interface StepItem {
  description?: ReactNode
  disabled?: boolean
  icon?: ReactNode
  status?: Status
  subTitle?: ReactNode
  name: string
  code: string
}

interface HistoryItem {
  code?: string
  date_start?: string
  date_end?: string
}

export interface StepsProps {
  className?: string
  current?: number
  direction: Direction
  items: StepItem[]
  history?: HistoryItem[]
}

export const StepsUi: React.FC<StepsProps> = (props: StepsProps) => {
  const {
    direction,
    current,
    className,
    items,
    history = [],
    ...otherProps
  } = props
  const {Step} = Steps

  const optionsList = useMemo(
    () =>
      items?.map(optionItem => {
        if (history.length !== 0) {
          if (history.find((historyItem) => historyItem?.code === 'done')) {
            const historySteps = history.map((historyItem) => historyItem.code)
            return <Step
              className='cardStep'
              title={optionItem.name}
              key={optionItem.code}
              status={historySteps.includes(optionItem.code) ? 'finish' : 'error'}
            />
          }
        }

        return <Step
          className='cardStep'
          title={optionItem.name}
          key={optionItem.code}
        />
      }),
    [items, history],
  )

  return <Steps
    className={classNames(cls.Step, {}, [className])}
    direction={direction}
    current={current}
    size='small'
    {...otherProps}
  >
    {optionsList}
  </Steps>
}
