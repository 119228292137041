import {Avatar, Comment} from 'antd'
import React from 'react'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import {MessageContainer} from './styles'
import {IAvitoChat} from './types'

dayjs.extend(localizedFormat)
dayjs().format('L LT')

interface IDataChat {
    data: IAvitoChat
}

export const MessageBox = (props: IDataChat) => {

    const {data} = props
    const getDate = (date: string) => {
        return dayjs(date).format('D MMMM YYYY в HH:mm:ss')
    }

    if (!data) {
        return null
    }

    return (
        <MessageContainer
        >
            {data?.chat?.map((item) => {
                if (item.direction === 'out') {
                    return <Comment
                        author='Менеджер'
                        avatar={<Avatar>M</Avatar>}
                        content={item.msg}
                        datetime={getDate(item.created_at)}
                        key={item.created_at}
                    />
                }
                if (item.direction === 'in') {
                    return <Comment
                        key={item.created_at}
                        className='incoming'
                        author='Кандидат'
                        avatar={<Avatar>К</Avatar>}
                        content={item.msg}
                        datetime={getDate(item.created_at)}
                    />
                }
            })}
        </MessageContainer>
    )
}
