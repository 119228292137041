import React, {memo, useMemo} from 'react'
import {useSelector} from 'react-redux'
import {getDataOfType} from '../../lib/utils/get-data-of-type'
import AppLoader from '../components/app-loader'
import {Redirect, Route, Switch} from 'react-router-dom'
import LoginPage from '../pages/login'
import CasesListPage from '../pages/list-cases'
import OrdersListPage from '../pages/list-orders'
import ListClients from '../pages/list-clients'
import TasksListOrderPage from '../pages/list-tasks-orders'
import EmployeeListPage from '../pages/list-employees'
import {TasksListPage} from '../pages/list-tasks'
import TasksCalendar from '../pages_v2/tasks-calendar'
import FrozesListPage from '../pages/list-measurements'
import ManufactureListPage from '../pages/list-manufacture'
import DeliveryListPage from '../pages/list-delivery'
import MountingListPage from '../pages/list-mounting'
import ListCallLog from '../pages/list-call-log'
import FrozeCardPage from '../pages/card-measurement'
import EditCasePage from '../pages/card-case'
import EditOrderPage from '../pages/card-order'
import CardClient from '../pages/card-client'
import ClientCardPage from '../pages/card-client'
import CardMountingPage from '../pages/card-mounting'
import CardDeliveryPage from '../pages/card-delivery'
import CardManufacturePage from '../pages/card-manufacture'
import CardEmployeePage from '../pages/card-employee'
import AuditCardPage from '../pages/card-audit'
import ComplaintCardPage from '../pages/card-complaint'
import PageListUsers from '../pages/list-users'
import PageCardUser from '../pages/card-user'
import PageListOrganizations from '../pages/list-organizations'
import PageCardOrganization from '../pages/card-organization'
import ReportsList from '../pages/list-reports'
import ReportCard from '../pages/card-report'
import PageListProfilesOrganizations from '../pages/list-profiles-organizations'
import PageCardProfileOrganization from '../pages/card-profile-organization'
import AuditListPage from '../pages/list-audit'
import ComplaintListPage from '../pages/list-complaint'
import ClaimsListPage from '../pages/list-claims'
import ClaimCardPage from '../pages/card-claim'
import ClaimRequestsListPage from '../pages/list-claim-requests'
import ClaimRequestCardPage from '../pages/card-claim-request'
import {AdminCollectionsListPage} from '../pages/list-admin-collections/admin-collections-list'
import AdminNewCollectionPage from '../pages/admin-new-collection'
import AdminEditCollectionPage from '../pages/admin-edit-collection'
import {AdminObjectsListPage} from '../pages/list-admin-objects'
import AdminNewObjectPage from '../pages/admin-new-object'
import AdminEditObjectPage from '../pages/admin-edit-object'
import AdminSchemasListPage from '../pages/list-admin-schemas'
import AdminNewSchemaPage from '../pages/admin-new-schema'
import AdminEditSchemaPage from '../pages/admin-edit-schema'
import {CalculatorPage} from '../pages/calculator'
import Error404Page from '../pages/error-404-not-found'
import {createSelector} from 'reselect'
import {IStore} from '../store/interfaces'
import {PrivateRoute} from '../utilits'
import MailsListPage from '../pages/list-mails'
import CardMail from '../pages/card-mail'

import ReportMarketingCard from '../pages/card-report-marketing'
import ReportMarketingList from '../pages/list-report-marketing'

import HrListPage from '../pages/list-hr'
import HrCardPage from '../pages/card-hr/new'

import Tasks from '../pages_v2/tasks'
import { AdminListRoles, AdminEditRole } from '../pages_v2/admin/roles'
import NewClientCardPage from '../pages_v2/clients/card'
import NewListClients from '../pages_v2/clients/list'
import NewOrdersListPage from '../pages_v2/orders/list'
import CardOrder from '../pages_v2/orders/card'
import OrganizationsList from '../pages_v2/organizations/list'
import OrganizationCard from '../pages_v2/organizations/card'
import OfficesList from '../pages_v2/offices/list'
import OfficeCard from '../pages_v2/offices/card'
import UserCardPage from '../pages_v2/users/card'
import UsersListPage from '../pages_v2/users/list'
import Collections from '../pages_v2/admin/collections'
import Enum from '../pages_v2/admin/enum'
import NewCasesListPage from '../pages_v2/appeals/list'
import CardAppeal from '../pages_v2/appeals/card'
import Fields from '../pages_v2/admin/fields'
import DadataTest from '../pages_v2/dadata-test'
import HrDashboard from '../pages/dashboard-hr'
import HrPaymentsList from '../pages_v2/hr-payments/list'
import HrPaymentsCard from '../pages_v2/hr-payments/card'
import EmployeeCard from '../pages_v2/employees/card'
import MenuEditor from '../pages_v2/admin/menu_editor'
import LogisticDeliveryListPage from '../pages_v2/deliveries/list'
import LogisticDeliveryCardPage from '../pages_v2/deliveries/card'
import MeasurementsList from '../pages_v2/mesurements/list'
import MeasurementsCardPage from '../pages_v2/mesurements/card'

export const DesktopRouter = memo(() => {
  const isAuthorizedSession = session => {
    return Boolean(
      session?.sessionData &&
      session?.sessionData?.user &&
      session?.sessionData?.user?.['id'] > 0 &&
      session?.sessionData?.roles &&
      session?.sessionData?.roles.length > 0
    )
  }

  const createPageSelector = createSelector(
    (state: IStore) => ({
      page: state.page,
      session: state.session,
    }),
    ({ page, session }) => ({ page, session }),
  )

  const { page, session } = useSelector(createPageSelector)
  const startPage = useMemo(
    () => getDataOfType(session, 'sessionData.start-page', String, '/sales/orders'),
    [session],
  )

  return (
    <>
      {page.isLoading && <AppLoader />}
      {!page.isLoading && (
        <Switch>
          <Route
            exact
            path="/"
            render={() =>
              isAuthorizedSession(session) ? <Redirect to={startPage} /> : <Redirect to="/login" />
            }
          />
          {/* повторно не рендерится */}
          <Route exact path="/login" component={LoginPage} />
          <PrivateRoute exact path="/call-center/cases" session={session}>
            {/* повторно не рендерится */}
            <CasesListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/sales/orders" session={session}>
            <OrdersListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/call-center/clients" session={session}>
            <ListClients />
          </PrivateRoute>
          <PrivateRoute exact path="/mails" session={session}>
            <MailsListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/mails/:id" session={session}>
            <CardMail />
          </PrivateRoute>
          <PrivateRoute exact path="/employees" session={session}>
            <EmployeeListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/planning/tasks" session={session}>
            <TasksListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/planning/tasks-orders" session={session}>
            <TasksListOrderPage />
          </PrivateRoute>
          <PrivateRoute exact path="/sales/measurements" session={session}>
            <FrozesListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/production-page" session={session}>
            <ManufactureListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/logistic-delivery" session={session}>
            <DeliveryListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/sales/mounting" session={session}>
            <MountingListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/call-center-telephony" session={session}>
            <ListCallLog />
          </PrivateRoute>
          <PrivateRoute exact path="/froze/:id/edit" session={session}>
            <FrozeCardPage />
          </PrivateRoute>
          <PrivateRoute exact path="/case/:id/edit" session={session}>
            <EditCasePage />
          </PrivateRoute>
          <PrivateRoute exact path="/order/:id/edit" session={session}>
            <EditOrderPage />
          </PrivateRoute>
          <PrivateRoute exact path="/clients/:id" session={session}>
            <CardClient />
          </PrivateRoute>
          <PrivateRoute exact path="/client/:id/edit" session={session}>
            <ClientCardPage />
          </PrivateRoute>
          <PrivateRoute exact path="/mounting/:id/edit" session={session}>
            <CardMountingPage />
          </PrivateRoute>
          <PrivateRoute exact path="/delivery/:id/edit" session={session}>
            <CardDeliveryPage />
          </PrivateRoute>
          <PrivateRoute exact path="/manufacture/:id/edit" session={session}>
            <CardManufacturePage />
          </PrivateRoute>
          <PrivateRoute exact path="/employees/:id" session={session}>
            <CardEmployeePage />
          </PrivateRoute>
          <PrivateRoute exact path="/audit/:id/edit" session={session}>
            <AuditCardPage />
          </PrivateRoute>
          <PrivateRoute exact path="/complaint/:id/edit" session={session}>
            <ComplaintCardPage />
          </PrivateRoute>
          <PrivateRoute exact path="/users" session={session}>
            <PageListUsers />
          </PrivateRoute>
          <PrivateRoute exact path="/user/:id" session={session}>
            <PageCardUser />
          </PrivateRoute>
          <PrivateRoute exact path="/organizations" session={session}>
            <PageListOrganizations />
          </PrivateRoute>
          <PrivateRoute exact path="/organizations/:id" session={session}>
            <PageCardOrganization />
          </PrivateRoute>
          <PrivateRoute exact path="/reports" session={session}>
            <ReportsList />
          </PrivateRoute>
          <PrivateRoute exact path="/report/marketing" session={session}>
            <ReportMarketingCard />
          </PrivateRoute>
          <PrivateRoute exact path="/report/marketing-list" session={session}>
            <ReportMarketingList />
          </PrivateRoute>
          <PrivateRoute exact path="/report/:id" session={session}>
            <ReportCard />
          </PrivateRoute>
          <PrivateRoute exact path="/profiles-organization" session={session}>
            <PageListProfilesOrganizations />
          </PrivateRoute>
          <PrivateRoute exact path="/profile-organization/:id" session={session}>
            <PageCardProfileOrganization />
          </PrivateRoute>
          <PrivateRoute exact path="/sales/audit" session={session}>
            <AuditListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/sales/complaint" session={session}>
            <ComplaintListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/reports-satels/claims" session={session}>
            <ClaimsListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/claims/:id" session={session}>
            <ClaimCardPage />
          </PrivateRoute>
          <PrivateRoute exact path="/claims/:id/edit" session={session}>
            <ClaimCardPage />
          </PrivateRoute>
          <PrivateRoute exact path="/claim-requests" session={session}>
            <ClaimRequestsListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/claim-requests/:id" session={session}>
            <ClaimRequestCardPage />
          </PrivateRoute>
          <PrivateRoute exact path="/claim-requests/:id/edit" session={session}>
            <ClaimRequestCardPage />
          </PrivateRoute>
          <PrivateRoute exact path="/hr/payments" session={session}>
            <HrPaymentsList />
          </PrivateRoute>
          <PrivateRoute exact path="/hr/payments/:id" session={session}>
            <HrPaymentsCard />
          </PrivateRoute>
          <PrivateRoute exact path="/hr/dashboard" session={session}>
            <HrDashboard />
          </PrivateRoute>
          <PrivateRoute exact path="/hr" session={session}>
            <HrListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/hr/:id" session={session}>
            <HrCardPage />
          </PrivateRoute>
          <PrivateRoute exact path="/admin" session={session}>
            <Redirect to="/admin/collections" />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/collections" session={session}>
            <AdminCollectionsListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/collections/new" session={session}>
            <AdminNewCollectionPage />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/collections/:id" session={session}>
            <AdminEditCollectionPage />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/collections/:name/objects" session={session}>
            <AdminObjectsListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/collections/:name/objects/new" session={session}>
            <AdminNewObjectPage />
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/admin/collections/:name/objects/:id/:group/edit"
            session={session}
          >
            <AdminEditObjectPage />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/schemas" session={session}>
            <AdminSchemasListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/schemas/new" session={session}>
            <AdminNewSchemaPage />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/schemas/:id" session={session}>
            <AdminEditSchemaPage />
          </PrivateRoute>
          <PrivateRoute exact path="/calculator" session={session}>
            <CalculatorPage/>
          </PrivateRoute>
          {/** Новые страницы */}
          <PrivateRoute exact path="/admin/roles" session={session}>
            <AdminListRoles />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/role/:id" session={session}>
            <AdminEditRole />
          </PrivateRoute>
          <PrivateRoute exact path="/appeals" session={session}>
            <NewCasesListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/appeal/:id" session={session}>
            <CardAppeal />
          </PrivateRoute>
          <PrivateRoute exact path="/orders" session={session}>
            <NewOrdersListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/order/:id" session={session}>
            <CardOrder />
          </PrivateRoute>
          <PrivateRoute exact path="/call-center/new-clients" session={session}>
            <NewListClients />
          </PrivateRoute>
          <PrivateRoute exact path="/client-new/:id" session={session}>
            <NewClientCardPage />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/fields" session={session}>
            <Fields />
          </PrivateRoute>
          <PrivateRoute exact path="/tasks" session={session}>
            <Tasks />
          </PrivateRoute>
          <PrivateRoute exact path="/tasks/calendar" session={session}>
            <TasksCalendar />
          </PrivateRoute>
          <PrivateRoute exact path="/new/organizations" session={session}>
            <OrganizationsList />
          </PrivateRoute>
          <PrivateRoute exact path="/new/organization/:id" session={session}>
            <OrganizationCard />
          </PrivateRoute>
          <PrivateRoute exact path="/offices/:id" session={session}>
            <OfficesList />
          </PrivateRoute>
          <PrivateRoute exact path="/office/:id" session={session}>
            <OfficeCard />
          </PrivateRoute>
          <PrivateRoute exact path="/office/:organization_id/new" session={session}>
            <OfficeCard />
          </PrivateRoute>
          <PrivateRoute exact path="/users-new" session={session}>
            <UsersListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/users-new/:id" session={session}>
            <UserCardPage />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/enum-collections" session={session}>
            <Collections />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/enum-collection/:id" session={session}>
            <Enum />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/roles" session={session}>
            <AdminListRoles />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/role/:id" session={session}>
            <AdminEditRole />
          </PrivateRoute>
          <PrivateRoute exact path="/dadata-test" session={session}>
            <DadataTest />
          </PrivateRoute>
          <PrivateRoute exact path="/employees_new/:user_id" session={session}>
            <EmployeeCard />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/menu-editor" session={session}>
            <MenuEditor />
          </PrivateRoute>
          <PrivateRoute exact path="/logistics/deliveries" session={session}>
            <LogisticDeliveryListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/logistics/deliveries/:id" session={session}>
            <LogisticDeliveryCardPage />
          </PrivateRoute>
          <PrivateRoute exact path="/measurements" session={session}>
            <MeasurementsList />
          </PrivateRoute>
          <PrivateRoute exact path="/measurements/:id" session={session}>
            <MeasurementsCardPage />
          </PrivateRoute>
          <Route component={Error404Page} />
        </Switch>
      )}
    </>
  )
})
