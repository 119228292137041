import React, { useEffect, useMemo, useState } from 'react'
import { Select } from './styles'
import { ISelect } from './interface'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import { Input, Tooltip } from 'antd'

function filterOption(input, option){
  return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

function SelectFromEnum({ value, schema, onChange, UISchema }: ISelect) {
  const [ localValue, setLocalValue ] = useState<any>()

  useEffect(() => {
    setLocalValue(value)
  }, [value])

  const props: {} = useMemo(() => {
    const props = {
      width: UISchema?.options?.width,
      allowClear: Boolean(UISchema?.options?.allowClear),
      showSearch: Boolean(UISchema?.options?.showSearch),
      placeholder: UISchema?.options?.placeholder ?? "Выберите запись",
      optionFilterProp: "children",
      onChange: onChange,
      defaultValue: value,
      filterOption: filterOption,
      disabled: Boolean(UISchema?.options?.disabled),
    }
    if(Boolean(UISchema?.options?.multiple)) {
      Object.assign(props, { mode: 'multiple' })
    }
    return props
  }, [UISchema])

  const objects: any[] = useMemo(() => {
    const values = getDataOfType(schema, 'enum', Array, [])
    const oneOf = getDataOfType(schema, 'oneOf', Array, [])
    const oneOfObject = Object.fromEntries(oneOf.map(item => [item['const'], item['title']]))
    return values.map(value => ({
      'name': value,
      'title': getDataOfType(oneOfObject, value, [String, Number], value)
    }))
  }, [schema])

  const afterButton: any = useMemo(() => {
    return UISchema?.options?.afterButton || null
  }, [UISchema])

  return (
    <Tooltip trigger={['hover']} title={UISchema?.tooltip || null} placement="topLeft">
      <Select
        { ...props }
        value={localValue}
      >
        {objects.map(item => (
          <Select.Option key={item['name']} value={item['name']}>{item['title']}</Select.Option>
        ))}
      </Select>
      {afterButton}
    </Tooltip>
  )
}

export default SelectFromEnum

