import React, { memo, Suspense, useCallback, useState } from 'react'
import { HStack } from '../../../../../../ui/Stack'
import { Button } from 'antd'
import { classNames } from '../../../../../../lib/classNames/classNames'
import { ModalCreateTask } from '../../../../../../views/modal-create-task-new'
import { ModalAddNote } from '../../../../../../views/modal-add-notes-new'
import {MeasurementEditDateModal} from '../../measurement-edit-date-modal'

interface MeasurementFormsHeaderProps {
  id: string | number,
  className?: string,
  stage?: number,
  isFinished?: boolean
  toOrder?: () => void,
  toClaim?: () => void,
  toFinish?: () => void,
}

const titlesButton = [
  'Взять в работу',
  'Зафиксировать приезд',
  'Замер произведен',
  'Договор подписан',
  'Выполнено',
]

export const MeasurementFormsHeader: React.FC<MeasurementFormsHeaderProps> = memo((props: MeasurementFormsHeaderProps) => {
  const { className, id, stage = 0, toOrder, toFinish, isFinished } = props
  const isDisabledButtons = false

  const [isOpenModalCreateTask, setIsOpenModalCreateTask] = useState<boolean>(false)
  const [isOpenModalAddNote, setIsOpenModalAddNote] = useState<boolean>(false)
  const [isOpenModalMeasurementEditDate, setIsOpenModalMeasurementEditDate] = useState<boolean>(false)

  const handleNextStageOnClick = useCallback(async () => {
    if (toOrder) {
      await toOrder?.()
    }
  }, [toOrder])

  const onCloseAddNote = useCallback(() => {
    setIsOpenModalAddNote(false)
  }, [setIsOpenModalAddNote])

  const onCloseCreateTask = useCallback(() => {
    setIsOpenModalCreateTask(false)
  }, [setIsOpenModalCreateTask])

  const onCloseMeasurementEditDateModal = useCallback(() => {
    setIsOpenModalMeasurementEditDate(false)
  }, [setIsOpenModalMeasurementEditDate])

  const handleFinishOnClick = useCallback(async () => {
    if (toFinish) {
      await toFinish?.()
    }
  }, [toFinish])

  return (
    <HStack gap={'8'} className={classNames('mapWrapper', {}, [className])}>
      <HStack gap={'8'} max justify={'start'} className={'h3'}>
        <>Замер {`№ ${id}`}</>
      </HStack>
      <HStack gap={'8'} max justify={'end'}>
        <Button
          onClick={() => setIsOpenModalAddNote(true)}
          disabled={isDisabledButtons}
        >
          Добавить заметку
        </Button>
        <Suspense fallback={''}>
          <Button
            onClick={() => setIsOpenModalCreateTask(true)}
            disabled={isDisabledButtons}
          >
            Создать задачу
          </Button>
          <ModalCreateTask
            isOpen={isOpenModalCreateTask}
            onClose={onCloseCreateTask}
            entityType={'case'}
            entityId={id}
          />
        </Suspense>
        <ModalAddNote
          entityId={Number(id)}
          entityType={'case'}
          isOpen={isOpenModalAddNote}
          onClose={onCloseAddNote}
        />
        {
          stage === 1 && (
            <>
              <Button
                onClick={() => setIsOpenModalMeasurementEditDate(true)}
                style={{marginBottom: 16, marginTop: 16}}
                type='default'
              >
                Изменить дату
              </Button>
              <MeasurementEditDateModal
                id={id}
                isOpen={isOpenModalMeasurementEditDate}
                onCancel={onCloseMeasurementEditDateModal}
              />
            </>
          )
        }
        {
          titlesButton[stage] && (
            <Button
              onClick={handleNextStageOnClick}
              style={{marginBottom: 16, marginTop: 16}}
              type='default'
            >
              {titlesButton[stage] ?? ''}
            </Button>
          )
        }
        { stage === 3 && (
            <Button
              onClick={handleFinishOnClick}
              style={{marginBottom: 16, marginTop: 16}}
              type='default'
            >
            Выполнено
          </Button>
        )}
      </HStack>
    </HStack>
  )
})
