export const convertData2JsonForm = (currentData) => {
    const resultObject = {}
    const resultSchema: SchemaI = {
        properties: {},
        required: [],
        type: "object"
    }
    currentData?.forEach(({name, value, options, required, title, input}: FormI) => {
        resultObject[name] = value;

        resultSchema.properties[name] = {
            title: title || name,
            ...input,
            options
        }
        if (required) {
            resultSchema.required.push(name);
        }
        if (input?.format === 'file-s3') {
            resultSchema.properties[name] = {
                ...resultSchema.properties[name],
                options: {
                    ...resultSchema.properties[name]?.options,
                    isNewUrl: true
                }
            }
        }
    });

    return {
        resultObject,
        resultSchema
    }
}
