import React, { memo } from 'react'
import { Modal } from 'antd'
import { DefaultJsonForm } from '../../../../../components/v2/default-json-form'

interface MeasurementModalProps {
  isOpen: boolean
  onOk?: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined
  onCancel?: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined
  title?: string
  centered?: boolean
  formData: any
  schema: any
  onChange: any
  disabled?: boolean
}

export const MeasurementModal: React.FC<MeasurementModalProps> = memo((props: MeasurementModalProps) => {
  const {
    isOpen,
    onCancel,
    onOk,
    formData,
    schema,
    onChange,
    title,
    centered,
    disabled,
    ...otherProps
  } = props

  const [isCustomer, setIsCustomer] = React.useState(false)

  const handleOk = (e: React.MouseEvent<HTMLElement>) => {
    onOk?.(e)
  }

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    onCancel?.(e)
  }

  if (!formData) {
    return null
  }

  return (
    <Modal
      title={title}
      onOk={handleOk}
      centered={centered}
      onCancel={handleCancel}
      visible={isOpen}
      destroyOnClose={true}
      okButtonProps={{
       disabled: disabled
      }}
      {...otherProps}
    >
      <DefaultJsonForm
        formData={formData}
        schema={schema}
        onChange={onChange}
        orientation='horizontal'
      />
    </Modal>
  )
})
