import moment from "moment";

export const schema = [
    {
        name: "date",
        title: "Дата оплаты",
        value: moment().toISOString(),
        required: true,
        input: {
            type: "string",
            format: "date",
        },
        options: {
            isDisabledDatePrev: true,
        },
    },
    {
        name: "sum",
        title: "Сумма оплаты",
        value: null,
        required: true,
        input: {
            type: "number",
        },
    },
]