import { createStore, applyMiddleware, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import { pageReducer } from './page/reducer'
import { loginReducer } from './login/reducer'
import { sessionReducer } from './session/reducer'
import { menuReducer } from './menu/reducer'
import { collectionsReducer } from './collections/reducer'
import { appealsReducer } from './appeals/reducer'
import { viewedReducer } from './viewed/reducer'
import { hrReducer } from './hr/reducer'
import { chatAvitoReducer } from './chat_avito/reducer'
import { appeal } from './appeal/reducer'
import { calendar } from './calendar/reducer'
import { menuEditor } from './menu_editor/reducer'
import { menuV2 } from './user_menu/reducer'
import { order } from './order/reducer'
import { ordersDetailReducer } from './ordersDetail/reducer'
import { timelineReducer } from './timeline/reducer'
import { stagePlanReducer } from './stagePlan/reducer'
import { deliveries } from './deliveries/reducer'
import { measurementReducer } from './measurement/reducer'

const reducers = combineReducers({
  page: pageReducer,
  login: loginReducer,
  session: sessionReducer,
  menu: menuReducer,
  menuV2: menuV2,
  menuEditor: menuEditor,
  collections: collectionsReducer,
  appeals: appealsReducer,
  viewed: viewedReducer,
  hr: hrReducer,
  appeal: appeal,
  order: order,
  calendar: calendar,
  chatAvito: chatAvitoReducer,
  ordersDetail: ordersDetailReducer,
  deliveries: deliveries,
  timeline: timelineReducer,
  stagePlan: stagePlanReducer,
  measurement: measurementReducer,
})

export default (initialState?: any) => {
  const middlewares = composeWithDevTools(
    applyMiddleware(thunkMiddleware)
  )
  return createStore(reducers, initialState, middlewares)
}
