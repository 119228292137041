import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { Alert, Form, Skeleton } from 'antd'
import { useSelector } from 'react-redux'
import {
  getMeasurementFormData,
  getMeasurementFormLoading,
  getVisibleFormData, getMeasurementFormError,
} from '../../selectors'
import { useDebounce } from 'src/react-app/hooks/useDebounce/useDebounce'
import { HStack, VStack } from '../../../../../ui/Stack'
import { HSteps } from '../../../../../ui/Steps'
import { DefaultJsonForm } from '../../../../../components/v2/default-json-form'
import { MeasurementFormsHeader } from './measurement-form-header/measurement-form-header'
import { useSaveResult } from '../../hooks/useSaveResult'
import { useHistory } from 'react-router-dom'

interface MeasurementFormsProps {
  className?: string
  id: string | number,
}

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 24},
  },
}

export const MeasurementForms: React.FC<MeasurementFormsProps> = memo((props: MeasurementFormsProps) => {
  const {id} = props
  const [form] = Form.useForm()
  const measurementFormData = useSelector(getMeasurementFormData)
  const measurementFormIsLoading = useSelector(getMeasurementFormLoading)
  const measurementFormError = useSelector(getMeasurementFormError)
  const [isDisabled, setIsDisabled] = useState<boolean>()
  const visibleFormData = useSelector(getVisibleFormData)
  const { saveResult } = useSaveResult()
  const history = useHistory()
  const isInitialRender = useRef(true)

  const saveCase = useCallback(async (is_next_stage, is_done = undefined) => {
    await saveResult(id, visibleFormData, measurementFormData?.stages?.current, is_next_stage, is_done)
  }, [history, id, visibleFormData])

  useEffect(() => {
    const redirectUrl = measurementFormData?.redirectUrl
    if (redirectUrl) {
      history.push(redirectUrl)
    }
  }, [measurementFormData])

  useEffect(() => {
    if (visibleFormData) {
      isInitialRender.current = false
    }
  }, [visibleFormData])

  const debounceSaveCase = useDebounce(saveCase, 1000)

  const onChangeForm = useCallback(
    async ({formData}) => {
      if (isInitialRender.current) {
        return
      }
      if (!visibleFormData) {
        return
      }
      const isFilesEdit = JSON.stringify(formData) === JSON.stringify(visibleFormData?.resultObject)
      if (isFilesEdit) {
        return
      }
      visibleFormData.resultObject = {...formData}
      debounceSaveCase()
    }, [visibleFormData, debounceSaveCase]
  )

  const handleToOrder = useCallback(async () => {
    setIsDisabled(true)
    await saveCase(true)
    setIsDisabled(false)
  }, [isDisabled, saveCase])

  const handleToFinish = useCallback(async () => {
    setIsDisabled(true)
    await saveCase(true, true)
    setIsDisabled(false)
  }, [])

  if (measurementFormError && !measurementFormIsLoading && !measurementFormData) {
    return (
      <HStack gap={'8'} className={'mapWrapper'}>
        При выполнении запроса возникла ошибка.
        Попробуйте перезагрузить страницу или открыть карточку повторно.
      </HStack>
    )
  }

  return (
    <>
      <MeasurementFormsHeader
        id={id}
        isFinished={measurementFormData?.stages && measurementFormData?.stages?.current === Object.keys(measurementFormData?.stages?.items).length - 1}
        stage={measurementFormData?.stages?.current}
        toOrder={handleToOrder}
        toFinish={handleToFinish}
      />
      <HStack gap={"8"} className={'mapContent'} max>
        <Form
          {...formItemLayout}
          layout='horizontal'
          form={form}
          scrollToFirstError={true}
          style={{width: '100%'}}
        >
          {measurementFormError && !measurementFormIsLoading ? (
            <div className={'alertWrapper'}>
              <Alert
                message='При выполнении операции возникла ошибка:'
                showIcon
                type='error'
                description={measurementFormError}
              />
            </div>
          ) : null}
          {!measurementFormIsLoading ? (
            <VStack gap={'16'} max align={'stretch'} className={'appealsForm'}>
              {measurementFormData?.stages && (
                <HSteps
                  items={Object.values(measurementFormData?.stages?.items)}
                  current={measurementFormData?.stages?.current}
                  history={measurementFormData?.stages?.history}
                />)
              }
              {
                visibleFormData && <DefaultJsonForm
                  formData={{...visibleFormData.resultObject}}
                  schema={visibleFormData.resultSchema}
                  onChange={onChangeForm}
                  orientation='horizontal'
                />
              }
            </VStack>
          ) : (
            <Skeleton active/>
          )}
        </Form>
      </HStack>
    </>
  )
})
