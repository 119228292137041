import React, { memo, Suspense, useCallback, useState } from 'react'
import { HStack } from '../../../../../../ui/Stack'
import { Button } from 'antd'
import { classNames } from '../../../../../../lib/classNames/classNames'
import { ModalCreateTask } from '../../../../../../views/modal-create-task-new'
import { ModalAddNote } from '../../../../../../views/modal-add-notes-new'
import { ModalCancel } from '../../../../../../views/modal-cancel'

interface FormsHeaderProps {
  id: string | number,
  className?: string,
  stage?: number,
  nextStageOnClick?: () => void,
}

const titlesButton = [
  'Консультация',
  'Назначить замер',
  'Договор',
  'Производство',
  'Монтаж',
  'Монтаж',
]

export const FormsHeader: React.FC<FormsHeaderProps> = memo((props: FormsHeaderProps) => {
  const { className, id, stage = 0, nextStageOnClick } = props
  const isDisabledButtons = false
  /*
  const { sessionData } = useSelector(createSession)
  const isDisabledByAccess = sessionData.roles.includes(19)
  */
  const [isOpenModalCreateTask, setIsOpenModalCreateTask] = useState<boolean>(false)
  const [isOpenModalAddNote, setIsOpenModalAddNote] = useState<boolean>(false)
  const [isOpenModalCancel, setIsOpenModalCancel] = useState<boolean>(false)

  const handleNextStageOnClick = useCallback(async () => {
    if (nextStageOnClick) {
      await nextStageOnClick?.()
    }
  }, [nextStageOnClick])

  const handleCancelOnClick = useCallback(() => {
    setIsOpenModalCancel(true)
  }, [setIsOpenModalCancel])

  const onCloseCancel = useCallback(() => {
    setIsOpenModalCancel(false)
  }, [setIsOpenModalCancel])

  const onCloseAddNote = useCallback(() => {
    setIsOpenModalAddNote(false)
  }, [setIsOpenModalAddNote])

  const onCloseCreateTask = useCallback(() => {
    setIsOpenModalCreateTask(false)
  }, [setIsOpenModalCreateTask])

  return (
    <HStack gap={'8'} className={classNames('mapWrapper', {}, [className])}>
      <HStack gap={'8'} max justify={'start'} className={'h3'}>
        Заказ {`№ ${id}`}
      </HStack>
      <HStack gap={'8'} max justify={'end'}>
        <Button
          onClick={() => setIsOpenModalAddNote(true)}
          disabled={isDisabledButtons}
        >
          Добавить заметку
        </Button>
        <Suspense fallback={''}>
          <Button
            onClick={() => setIsOpenModalCreateTask(true)}
            disabled={isDisabledButtons}
          >
            Создать задачу
          </Button>
          <ModalCreateTask
            isOpen={isOpenModalCreateTask}
            onClose={onCloseCreateTask}
            entityType={'case'}
            entityId={id}
          />
        </Suspense>
        <ModalAddNote
          entityId={Number(id)}
          entityType={'case'}
          isOpen={isOpenModalAddNote}
          onClose={onCloseAddNote}
        />
        {
          titlesButton[stage] && (
            <Button
              onClick={handleNextStageOnClick}
              style={{marginBottom: 16, marginTop: 16}}
              type='default'
            >
              {titlesButton[stage] ?? ''}
            </Button>
          )
        }
        {isDisabledButtons && (
          <Suspense fallback={''}>
            <Button danger onClick={handleCancelOnClick}>
              Отмена
            </Button>
            <ModalCancel
              entityId={id}
              entityType='order'
              isOpen={isOpenModalCancel}
              onClose={onCloseCancel}
            />
          </Suspense>
        )}
      </HStack>
    </HStack>
  )
})
