export const deleteTimelineItem = (deletedItem, timelineItems, timelineDates) => {
  const index = timelineDates?.findIndex(date => date === deletedItem.date)
  if (index !== -1) {
    const itemsCount = timelineItems[index].items.length
    if (itemsCount === 1) {
      timelineItems.splice(index, 1)
      timelineDates.splice(index, 1)
    } else {
      timelineItems[index].items = timelineItems[index].items.filter((activityItem, indexActivityItem) => {
        return indexActivityItem !== deletedItem.index && activityItem.data.id !== deletedItem.data.id
      })
    }
  }
  return { timelineItems: [...timelineItems], timelineDates: [...timelineDates] }
}
