import {useCallback, useRef} from 'react'


export function useEventSource() {
  const sse = useRef<EventSource | null>(null)

  const subscribe = useCallback((url: string, callback) => {
    try {
      const realUrl = process.env?.DEVELOPER ? `http://localhost:7070${url}` : url
      console.log(`subscribe to eventSource url: ${realUrl}`)
      sse.current = new EventSource(realUrl)
      sse.current.onmessage = function (event) {
        const data = JSON.parse(event?.data)
        if (data) {
          return callback(data)
        }
      }
    } catch (err) {
      console.log(`eventSource ${url} err: `, err)
    }
  }, [])

  return { sse, subscribe }
}
