import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import B2BLayout from '../../../layouts/b2b'
import {Button, Checkbox, Form, Input, Modal, Skeleton, Switch, Table} from 'antd'
import fetchAPI from '../../../../lib/utils/fetch-api'
import {EditButton, DeleteButton, CreateButton} from '../../elements/buttons'
import {useHistory, useParams} from 'react-router-dom'
import {MapContent, MapTitle, TaskWrapper} from '../../../pages/card-employee/styles'
import {useForm} from 'antd/es/form/Form'
import {fetchV2} from '../../utils'

export const AdminListRoles = () => {
  const history = useHistory()
  const [data, setData] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [removableID, setRemovableID] = useState(null)
  const [inRequest, setInRequest] = useState(false)

  useEffect( () => {
    setTimeout( () => {
    }, 3000)
    getData()
  }, [])

  const getData = useCallback( async () => {
    await fetchAPI('/api/roles/list',{method: 'GET'})
      .then(data => {
        if (Array.isArray(data)) {
          setData(data)
        }else{
          console.error('Неверный тип данных', { data })
        }
      })
      .catch(e => {
        console.error('Не удалось получить список', {e})
      })
      .finally( () => {
        setLoading(false)
      })
  }, [])

  /**
   * Хлебные крошки
   */
  const breadcrumbs = useMemo(() => (
    [
      {
        href: '/admin',
        title: 'Админ панель',
      },
      {
        href: location.pathname,
        title: 'Список ролей',
      }
    ]
  ), [location.pathname])

  const handleEditRecord = useCallback(id => {
    history.push(`/admin/role/${id}`)
  }, [])

  const handleNewRecord = useCallback(() => {
    history.push(`/admin/role/new`)
  }, [])

  const handleModalOk = useCallback( async () => {
    try {
      const result = await fetchV2(`/api/roles/${removableID}`, {method: 'DELETE'})
      if(result.status === 200){
        setRemovableID(null)
        setIsModalVisible(false)
        getData()
      }else{
        alert(result.body.message)
      }
    }catch (e) {
      console.error('Не удалось удалить!',{e})
    }
  }, [removableID])

  const openRemoveModal = useCallback ( id => {
    setIsModalVisible(true)
    setRemovableID(id)
  }, [setRemovableID, setIsModalVisible])

  const getRemoveRoleName = useCallback( () => {
    return data.filter( i => i.id === removableID )?.[0]?.['name'] || null
  }, [data, removableID])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <Modal
        title="Подтвердите действие"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={() => { setIsModalVisible(false) }}
        okText='Удалить'
        cancelButtonProps={{disabled: inRequest}}
        okButtonProps={{className: 'ant-btn ant-btn-danger', disabled: inRequest}}
      >
        <h2>Вы уверены, что хотите удалить роль "{getRemoveRoleName()}" ?</h2>
        <p>
          Удаление возможно только при условии, что ни одному из пользователей данная роль не назначена.
          Так же будут очищены все назначенные уровни доступа.
        </p>
      </Modal>
      <CreateButton onClick={ () => { handleNewRecord() } } name={'Создать роль'}/>
      <Table
        style={{marginTop: 20}}
        rowKey={'id'}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
            width: 150
          },
          {
            title: 'Название',
            dataIndex: 'name'
          },
          {
            title: 'Активно',
            dataIndex: 'is_active',
            render: data => <>{ data ? 'Да' : 'Нет' }</>,
            width: 100
          },
          {
            title: 'Действия',
            key: 'operation',
            className: 'no-edit',
            fixed: 'right',
            width: 100,
            align: 'center',
            render: (_, {id}) =>  (
              <>
                <EditButton onClick={ () => handleEditRecord(id) }/>
                <DeleteButton onClick={ () => openRemoveModal(id) }/>
              </>
            )
          }
        ]}
        dataSource={data}
        loading={loading}
        size="small"
        pagination={false}
      />
    </B2BLayout>
  )
}

export const AdminEditRole = () => {
  const {id} = useParams() as any
  const formRef = useRef(null)
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(true)
  const [form] = useForm()
  const [permissions, setPermissions] = useState<any>(null)

  /**
   * Хлебные крошки
   */
  const breadcrumbs = useMemo(() => (
    [
      {
        href: '/admin',
        title: 'Админ панель',
      },
      {
        href: '/admin/roles',
        title: 'Список ролей',
      }
    ]
  ), [location.pathname])

  useEffect( () => {
    getData()
    getPermissions()
  }, [])

  const getPermissions = useCallback( async () => {
    await fetchAPI(`/api/roles/permissions-list`,{method: 'GET'})
      .then(data => {
        setPermissions(data)
      })
      .catch(e => {
        console.error('Не удалось получить список уровней доступа', {e})
      })
  }, [])

  const getData = useCallback( async () => {
    if( !id || id === 'new'){
      form.setFieldsValue({
        is_active: true,
        permissions: []
      })
      setLoading(false)
      return
    }
    await fetchAPI(`/api/roles/${id}`,{method: 'GET'})
      .then(data => {
        const formData = {...data?.role, ...{permissions: data?.role_permissions}}
        form.setFieldsValue(formData)
      })
      .catch(e => {
        console.error('Не удалось получить данные', {e})
      })
      .finally( () => {
        setLoading(false)
      })
  }, [id])

  const onSubmit = async () => {
    try {
      const values = await form.validateFields()
      const permissions = form.getFieldValue('permissions')
      const data = {...values, permissions}
      const route = id === 'new' ? 'new' : `update/${id}`
      await fetchAPI(`/api/roles/${route}`,{method: 'POST', body: JSON.stringify(data)})
        .then(() => {
          history.push('/admin/roles')
        })
        .catch(e => {
          console.error('Не удалось сохранить данные', {e})
        })
    } catch (errorInfo) {
      const err = form.getFieldsError()
      if(err) {
        const fieldName = err?.[0]?.['name']?.[0]
        if(fieldName) {
          form.scrollToField(fieldName, {
            behavior: 'smooth',
            block: 'center',
          })
        }
      }
      console.error('Failed:', {errorInfo})
    }
  }

  const handlePermissionChange = e => {
    const { value, checked } = e.target
    const updatedPermissions = [...form.getFieldValue('permissions')]

    if (checked) {
      updatedPermissions.push(value);
    } else {
      const index = updatedPermissions.indexOf(value)
      if (index > -1) {
        updatedPermissions.splice(index, 1)
      }
    }
    form.setFieldsValue({permissions: updatedPermissions})
  }

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <TaskWrapper>
        <MapTitle>
          {
            loading ? null :
              form.getFieldValue('name')
                ?
                <>Редактирование роли <span>{`${form.getFieldValue('name')}`}</span></>
                :
                <>Создание новой роли</>
          }
        </MapTitle>
      </TaskWrapper>
      <MapContent>
        { !loading ?
          <Form
            form={form}
            ref={formRef}
            layout={'horizontal'}
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 14 }}
          >
            <Form.Item
              label='Название'
              name='name'
              rules={[{ required: true, message: 'Укажите название!' }]}
              >
              <Input autoComplete='new'/>
            </Form.Item>
            <Form.Item label="Активна" name='is_active'>
              <Switch defaultChecked={ form.getFieldValue('is_active') }/>
            </Form.Item>
              {
                (permissions) &&
                Object.keys(permissions).map( key => {
                  const p = permissions[key]
                  return (
                    <Form.Item
                      style={{marginLeft:'8.33%', marginBottom: 8}}
                      key={p.id}
                    >
                      <Checkbox
                        key={p.id}
                        defaultChecked={ form.getFieldValue('permissions').includes(p.id) }
                        onChange={ handlePermissionChange }
                        value={p.id}
                      >
                        {p.description}
                      </Checkbox>
                    </Form.Item>
                  )
                })
              }
            <Form.Item wrapperCol={{ offset: 2, span: 14 }} style={{marginTop: 30}}>
              <Button type="primary" htmlType="submit" onClick={onSubmit}>
                Сохранить
              </Button>
            </Form.Item>
          </Form>
          :
          <Skeleton active/>
        }
      </MapContent>
    </B2BLayout>
  )
}
