import {Space} from "antd";
import cls from './activity-timeline-item-header-text.module.css'
import React, {memo, ReactNode} from "react";
import {classNames} from "../../../../../../lib/classNames/classNames";

interface TimelineItemHeaderTextProps {
    className?: string,
    title?: ReactNode,
    text?: ReactNode
}

const TimelineItemHeaderText: React.FC<TimelineItemHeaderTextProps> = memo((props: TimelineItemHeaderTextProps) => {
    const {className, title, text} = props;
    return (
        <Space className={classNames(cls.TimelineItemHeaderText, {}, [className])}>
            <span className={cls.title}>{title}</span>
            <span className={cls.text}>{text}</span>
        </Space>
    )
})
export default TimelineItemHeaderText
