import React, {useState, useRef, useEffect, useCallback} from 'react'
import {useParams, useHistory} from 'react-router-dom'
import {Form, Alert, Skeleton, Button, Upload} from 'antd'
import type {UploadProps} from 'antd/es/upload/interface'
import {SaveOutlined, UploadOutlined} from '@ant-design/icons'
import {MapContent, MapTitle, MapWrapper, AlertWrapper, TaskWrapper, UploadWrapper} from './styles'
import B2BLayout from '../../layouts/b2b'
import './index.css'
import {getDataOfType} from 'src/lib/utils/get-data-of-type'
import {DefaultJsonForm} from '../../components/default-json-form'
import {
  createUISchemaHorizontal,
} from 'src/lib/utils/grid-fild-orientation'
import claimFieldsSchema from './json-schema.json'
import fetchAPI from 'src/lib/utils/fetch-api'

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 24},
  },
}

const breadcrumbs = [
  {
    href: '/reports-satels/claims',
    title: 'Отчеты Satels',
  },
  {
    href: `/reports-satels/claims`,
    title: 'Список рекламаций',
  },
]

export default function AdminNewObjectPage() {
  const {id} = useParams() as any
  const history = useHistory()
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const formDataRef = useRef<any>({})
  const [UISchema, setUISchema] = useState<any>(null)
  const [form] = Form.useForm()
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<any>(null)
  const [fileList, setFileList] = useState<any>([])
  const clientsRef = useRef<any>([])
  const selectedClientRef = useRef<any>({})

  const saveCase = useCallback(
    async () => {
      const formData = {...formDataRef.current}
      const claimId = id !== 'new' && id || ''
      if (selectedClientRef.current?.id) {
        formData.client_num = selectedClientRef.current.id
        formData.client_name = selectedClientRef.current.name
      }
      await fetchAPI(`/api/claims/${claimId}`, {method: 'POST', body: JSON.stringify(formData)})

      history.push('/reports-satels/claims')
    },
    [history, id]
  )

  const getData = useCallback(async () => {
    {
      const result = id !== 'new' && await fetchAPI(`/api/claims/${id}`) || []

      const claimData = getDataOfType(result, 'data', Object, {})
      {
        await fetchAPI('/api/claims/enum')
          .then(result => {
            const enumItems = result?.['data'] || {}

            Object.keys(enumItems).forEach(code => {
              if (claimFieldsSchema?.properties.hasOwnProperty(code)) {
                const enumValues = result?.['data']?.[code] || {}
                const oneOf: Record<string, string>[] = []

                Object.keys(enumValues).forEach(enumValue => {
                  return oneOf.push({
                    'const': enumValue,
                    'title': enumValues[enumValue]
                  })
                })

                Object.assign(claimFieldsSchema.properties[code], {
                  oneOf: oneOf,
                  'enum': Object.keys(enumValues),
                  view: {'name': 'select-from-enum'}
                })

                if (claimData.hasOwnProperty(code)) {
                  claimData[code] = claimData[code]?.toString() || null
                }
              }
            })
          })
      }

      const allClients = await fetchAPI('/api/claims_request_customer')
      if (allClients.length) {
        const enumValues: string[] = [];
        const oneOf: Record<string, string>[] = []
        allClients.forEach(client => {
          oneOf.push({
            'const': client.id,
            'title': client.name
          })
          enumValues.push(client.id)

        })
        Object.assign(claimFieldsSchema.properties['client_name'], {
          oneOf: oneOf,
          'enum': enumValues,
          view: {'name': 'select-from-enum'},
          options: {
            showSearch: true
          }
        })
        clientsRef.current = allClients
      }

      if (claimData?.files && typeof claimData?.files === 'object' && claimData?.files.length) {
        const newFileList: any[] = [];
        for (const i in claimData?.files) {
          const url = claimData?.files[i];
          const filename = url.split('/').pop()

          newFileList.push({
            uid: i,
            name: filename,
            status: 'done',
            url: url,
          })
        }
        setFileList(newFileList)
      }

      formDataRef.current = {...claimData}
      setMatchedJSONSchema(claimFieldsSchema)
      const UISchema = createUISchemaHorizontal(claimFieldsSchema)
      if (UISchema.claims) {
        UISchema.claims.css = {
          gridColumn: "1 / span 9",
          gridRow: "7"
        }
      }
      setUISchema(UISchema)

      setUISchema(UISchema => {
        const UISchemaNext = {...UISchema}
        const properties = Object.entries(UISchemaNext)
          .map(([key, value]: [string, any], index) => {
            const options = {}
            if (claimData['status'] === '1' && key !== 'status') {
              options['disabled'] = true;
            }
            if (key === 'price_close_complaints') {
              options['disabled'] = true;
            }
            if (key === 'client_name') {
              options['showSearch'] = true;
            }
            if (key === 'client_num') {
              options['disabled'] = true;
            }

            return [key, {...value, options: options}]
          })

        return Object.fromEntries(properties)
      })
    }

    setPageErrors([])
    setIsDataLoading(false)
  }, [id])

  useEffect(() => {
    getData()
  }, [getData])

  const onFormData = useCallback(
    ({formData}) => {
      if (formData.client_name && selectedClientRef.current?.id !== formData.client_name) {
        setIsDataLoading(true)
        const client = clientsRef.current.find(client => client.id === formData.client_name)
        if (client) {
          formData.client_num = client.id
          selectedClientRef.current = client
        }
      }
      formDataRef.current = formData
      setIsDataLoading(false)
    }, []
  )

  const localOnChange: UploadProps['onChange'] = (info) => {
    let newFileList = [...info.fileList]

    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url
      }
      return file
    })

    fetchAPI(`/api/claims/${id}/upload_file`, {method: 'POST', body: JSON.stringify(newFileList)})
    setFileList(newFileList)
  }

  const props = {
    name: 'file',
    action: `/api/upload`,
    onChange: localOnChange,
  }

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>

      <MapWrapper>
        <TaskWrapper>
          <MapTitle>
            Рекламация <span>{`№ ${id}`}</span>
          </MapTitle>
        </TaskWrapper>
        <MapContent>
          <Form {...formItemLayout} layout="horizontal" form={form} scrollToFirstError={true}>
            {pageErrors.length > 0 ? (
              <AlertWrapper>
                <Alert
                  message="При выполнении операции возникла ошибка:"
                  showIcon
                  type="error"
                  description={pageErrors.join('. ')}
                />
              </AlertWrapper>
            ) : null}
            {!isDataLoading ? (
              UISchema && (
                <>
                  <DefaultJsonForm
                    formData={formDataRef.current}
                    schema={matchedJSONSchema}
                    onChange={onFormData}
                    UISchema={UISchema}
                  />
                  <UploadWrapper>
                    <Upload {...props} fileList={fileList} className="upload-wrapper">
                      <Button icon={<UploadOutlined/>}>
                        Загрузить файл
                      </Button>
                    </Upload>
                  </UploadWrapper>
                </>
              )
            ) : (
              <Skeleton active/>
            )}
          </Form>
          <Button onClick={saveCase} style={{marginBottom: 16, marginTop: 16}} type="primary">
            <SaveOutlined/>
            Сохранить
          </Button>
        </MapContent>
      </MapWrapper>
    </B2BLayout>
  )
}
