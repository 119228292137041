import fetchAPI from '../../../lib/utils/fetch-api'
import {
  setHrCancelFormData,
  setHrEnumsData,
  setHrEnumsFailed,
  setHrEnumsLoading,
  setHrFormCandidateId,
  setHrFormData,
  setHrFormFailed,
  setHrFormLoading,
  setHrFormSuccessUpdate,
} from './actions'

export function getHrForm(id) {
  return async dispatch => {
    dispatch(setHrFormLoading());
    try {
      const response = await fetchAPI(`/api/hr/request/${id}`) || [];

      if (response['errors'] || response?.error) {
        let error = response['errors'] || response?.error;
        if (Array.isArray(error)) {
          error =  error.join( );
        }
        return dispatch(setHrFormFailed(error));
      }

      if (response['cancelModal']) {
        dispatch(setHrCancelFormData(response['cancelModal']));
      }

      return dispatch(setHrFormData(response));
    } catch (err) {
      return dispatch(setHrFormFailed(err as string));
    }
  }
}

export function nextStage(id, formData) {
  return async dispatch => {
    dispatch(setHrFormLoading());
    try {
      const response =  await fetchAPI(
          `/api/hr/request/${id}`,
          {
            method: 'POST', body: JSON.stringify(formData)
          }
      )

      if (response['errors']) {
        let error = response['errors'];
        if (Array.isArray(error)) {
          error =  error.join( );
        }
        return dispatch(setHrFormFailed(error));
      }

      return dispatch(setHrFormData(response));
    } catch (err) {
      return dispatch(setHrFormFailed(err as string));
    }
  }
}


export function updateFormData(id, formData) {
  return async dispatch => {
    try {
      const response =  await fetchAPI(
          `/api/hr/request/${id}`,
          {
            method: 'POST', body: JSON.stringify(formData)
          }
      )

      if (response['errors']) {
        let error = response['errors'];
        if (Array.isArray(error)) {
          error =  error.join( );
        }
        return dispatch(setHrFormFailed(error));
      }

      if ((!id || id === 'new') && response?.id) {
        return dispatch(setHrFormCandidateId(response.id));
      }

      return dispatch(setHrFormSuccessUpdate());
    } catch (err) {
      return dispatch(setHrFormFailed(err as string));
    }
  }
}



export function getHrEnums() {
  return async dispatch => {
    dispatch(setHrEnumsLoading());
    try {
      const response = await fetchAPI('/api/hr/request/enum');

      if (response['errors']) {
        let error = response['errors'];
        if (Array.isArray(error)) {
          error =  error.join( );
        }
        return dispatch(setHrEnumsFailed(error));
      }

      return dispatch(setHrEnumsData(response));
    } catch (err) {
      return dispatch(setHrEnumsFailed(err as string));
    }
  }
}

